import { AddressProps } from '@rsa-digital/evo-shared-components/components/Form/AddressInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { LookupValue } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import addressSearch from 'apiHelpers/address/addressSearch';
import addressLookupClient from 'apiHelpers/addressLookupClient';
import React from 'react';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import TypeaheadInput from 'components/TypeaheadInput';
import {
  PageTitle,
  trackAPIError,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { fullAddressToString } from 'helpers/formatAddress';
import {
  INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE,
  INPUT_REGEX_ALPHANUMERIC,
  INPUT_REGEX_ALPHANUMERIC_WITH_SPACE_HYPHEN_APOSTROPHE,
} from 'helpers/inputRegexes';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import useLoadingState from 'helpers/useLoadingState';
import {
  AddressDetails,
  AddressInfo,
  initialPostcodeLookup,
} from 'state/formData/customerDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import usePostcodeLookupErrors from './lookupErrors';
import useAddressQuestions from './questions';
import { AddressInputStyled } from './styles';

type AddressFormProps = {
  addressDetails: AddressDetails;
  updateAddressDetails: (update: Partial<AddressDetails>) => void;
  formValidation: {
    showValidation: FieldFunction<AddressDetails, void>;
    getError: FieldFunction<AddressDetails, string | undefined>;
  };
};

const AddressForm: React.FC<AddressFormProps> = ({
  addressDetails,
  updateAddressDetails,
  formValidation: { showValidation, getError },
}) => {
  const { withLoadingState: withFullAddressLoadingState } = useLoadingState();
  const { postcodeLookup, manual, actionText } = useAddressQuestions();
  const lookupErrors = usePostcodeLookupErrors();
  const countiesRefData = useReferenceData('counties')?.counties ?? [];

  const mappedCountiesRefData = countiesRefData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  const postcodeSearchProps: AddressProps<AddressInfo>['postcodeSearchFieldProps'] = {
    id: 'postcodeLookup',
    error: getError('postcodeLookup'),
    label: postcodeLookup.postcode.questionText,
    placeholder: postcodeLookup.postcode.placeholder,
    explanatoryText: postcodeLookup.postcode.explanatoryText,
    tooltip: postcodeLookup.postcode.tooltip,
    alertBody: postcodeLookup.postcode.alertText,
    validate: () => showValidation('postcodeLookup'),
    onFocus: trackFormTextFieldFocus('postcodeLookup'),
  };

  const addressSelectProps: AddressProps<AddressInfo>['addressSelectFieldProps'] = {
    id: 'address',
    error: getError('address'),
    label: postcodeLookup.selectAddress.questionText,
    placeholder: postcodeLookup.selectAddress.placeholder,
    explanatoryText: postcodeLookup.selectAddress.explanatoryText,
    tooltip: postcodeLookup.selectAddress.tooltip,
    alertBody: postcodeLookup.selectAddress.alertText,
    validate: () => showValidation('address'),
    onFocus: trackFormDropdownFocus('address', PageTitle.AboutYou),
    // These should match the props we pass through for the select component
    icon: 'expand',
    iconSize: 'small',
    useNeutralColouredIcon: true,
  };

  const displayText: AddressProps<AddressInfo>['displayText'] = {
    switchToManualBeforeSearch: actionText.switchToManualBeforeSearch,
    switchToManualAfterSearch: actionText.switchToManualAfterSearch,
    changeAddress: actionText.changeAddress,
    findAddress: actionText.buttonText,
    switchToPostcodeSearch: actionText.switchToPostcodeLookup,
    manualInputLegend: manual.sectionLabel,
  };

  const mappedAddress: AddressInfo | undefined = addressDetails.address && {
    description: `${fullAddressToString(addressDetails.address)}, ${
      addressDetails.postcodeLookup.lookupKey
    }`,
  };

  return (
    <AddressInputStyled
      id="addressEntry"
      address={mappedAddress}
      setAddress={async (address: AddressInfo | null) => {
        if (address === null) {
          updateAddressDetails({
            address: undefined,
            postcodeLookup: initialPostcodeLookup,
          });
        } else {
          const updatedAddress = await withFullAddressLoadingState(() =>
            addressLookupClient.getFullAddress(
              addressDetails.postcodeLookup.lookupKey.trim(),
              address.id
            )
          );
          updateAddressDetails({
            address: updatedAddress || undefined,
            usePreviousAddress: false,
          });
          trackFormDropdownSelect('address');
        }
      }}
      addressToString={(address: AddressInfo) => address.description}
      useManualEntry={addressDetails.isManualAddress}
      setUseManualEntry={(manualEntry: boolean) =>
        updateAddressDetails({ isManualAddress: manualEntry })
      }
      postcodeLookup={addressDetails.postcodeLookup}
      updatePostcodeLookup={(lookupValue: LookupValue<string, AddressInfo[]>) => {
        const formattedLookupValue = lookupValue.lookupKey
          .replace(/[^a-zA-Z0-9]+/g, '')
          .substring(0, 8)
          .toUpperCase();
        if (formattedLookupValue?.match(INPUT_REGEX_ALPHANUMERIC)) {
          updateAddressDetails({
            postcodeLookup: {
              ...lookupValue,
              lookupKey: formattedLookupValue?.toUpperCase().trimStart().substring(0, 8),
            },
          });
        }
      }}
      searchForPostcode={async (postcode: string) => {
        const addressOptions = await addressSearch(postcode);
        if (Array.isArray(addressOptions)) {
          return addressOptions;
        }

        updateAddressDetails({
          address: addressOptions,
        });
        return [{ description: fullAddressToString(addressOptions) }];
      }}
      displayText={displayText}
      lookupErrorMessages={lookupErrors.postcode}
      selectLookupErrorMessages={lookupErrors.select}
      postcodeSearchFieldProps={postcodeSearchProps}
      addressSelectFieldProps={addressSelectProps}
      onButtonClick={(label: string) => trackTextButtonClick(PageTitle.AboutYou, label)}
      onLookupError={trackAPIError}
      data-cs-mask>
      <QuestionField
        question={manual.flatNameOrNumber}
        errorText={getError('flatNameOrNumber')}>
        <NameInput
          id="flatNameOrNumber"
          placeholder={manual.flatNameOrNumber.placeholder}
          maxLength={30}
          value={addressDetails.flatNameOrNumber}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SPACE_HYPHEN_APOSTROPHE)
            ) {
              updateAddressDetails({
                flatNameOrNumber: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onPaste={(event: React.ClipboardEvent<HTMLElement>): void => {
            event.preventDefault();
            updateAddressDetails({
              postcode: event.clipboardData
                .getData('text/plain')
                .replace(/[^a-zA-Z0-9]+/g, '')
                .substring(0, 8)
                .toUpperCase()
                .trim(),
            });
          }}
          onBlur={() => {
            showValidation('flatNameOrNumber');
          }}
          onFocus={trackFormTextFieldFocus('flatNameOrNumber')}
        />
      </QuestionField>
      <QuestionField
        question={manual.houseNameOrNumber}
        errorText={getError('houseNameOrNumber')}>
        <NameInput
          id="houseNameOrNumber"
          placeholder={manual.houseNameOrNumber.placeholder}
          maxLength={30}
          value={addressDetails.houseNameOrNumber}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SPACE_HYPHEN_APOSTROPHE)
            ) {
              updateAddressDetails({
                houseNameOrNumber: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('houseNameOrNumber');
          }}
          onFocus={trackFormTextFieldFocus('houseNameOrNumber')}
        />
      </QuestionField>
      <QuestionField question={manual.street} errorText={getError('street')}>
        <NameInput
          id="street"
          placeholder={manual.street.placeholder}
          maxLength={35}
          value={addressDetails.street}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)
            ) {
              updateAddressDetails({
                street: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('street');
          }}
          onFocus={trackFormTextFieldFocus('street')}
        />
      </QuestionField>
      <QuestionField question={manual.town} errorText={getError('town')}>
        <NameInput
          id="town"
          placeholder={manual.town.placeholder}
          maxLength={35}
          value={addressDetails.town}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)
            ) {
              updateAddressDetails({
                town: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => showValidation('town')}
          onFocus={trackFormTextFieldFocus('town')}
        />
      </QuestionField>
      <QuestionField question={manual.county} errorText={getError('county')}>
        <TypeaheadInput
          id="county"
          placeholder={manual.county.placeholder}
          value={mappedCountiesRefData.find(
            (option) => option.id === addressDetails.county
          )}
          options={mappedCountiesRefData}
          onChange={(option) => {
            updateAddressDetails({
              county: option?.id || '',
            });
            trackFormDropdownSelect('county');
          }}
          minLength={1}
          onBlur={() => showValidation('county')}
          onFocus={trackFormDropdownFocus('county', PageTitle.AboutYou)}
        />
      </QuestionField>
      <QuestionField question={manual.postcode} errorText={getError('postcode')}>
        <TextInput
          id="postcode"
          placeholder={manual.postcode.placeholder}
          maxLength={8}
          value={addressDetails.postcode}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC)) {
              updateAddressDetails({
                postcode: e.target.value.toUpperCase().trim(),
              });
            }
          }}
          onPaste={(event: React.ClipboardEvent<HTMLElement>): void => {
            event.preventDefault();
            updateAddressDetails({
              postcode: event.clipboardData
                .getData('text/plain')
                .replace(/[^a-zA-Z0-9]+/g, '')
                .substring(0, 8)
                .toUpperCase()
                .trim(),
            });
          }}
          onBlur={() => {
            showValidation('postcode');
          }}
          onFocus={trackFormTextFieldFocus('postcode')}
        />
      </QuestionField>
    </AddressInputStyled>
  );
};

export default AddressForm;
