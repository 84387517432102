import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { MouseEventHandler } from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { Container, StyledHeading, StyledText } from './styles';

type AdditionalFormSectionBannerProps = {
  addFormSectionButtonClick?: MouseEventHandler;
  headingText: string;
  bodyText: string;
  buttonText?: string;
  buttonAriaLabel?: string;
  buttonId?: string;
  pageTitle: PageTitle;
} & ComponentProps;

const AdditionalFormSectionBanner: React.FC<AdditionalFormSectionBannerProps> = ({
  addFormSectionButtonClick,
  headingText,
  bodyText,
  buttonText,
  buttonAriaLabel,
  buttonId,
  pageTitle,
  ...props
}) => {
  return (
    <Container {...componentProps(props)}>
      <StyledHeading>{headingText}</StyledHeading>
      <StyledText html={bodyText} pageTitle={pageTitle} />
      {buttonId && (
        <AddButton
          onClick={addFormSectionButtonClick}
          id={buttonId}
          aria-label={buttonAriaLabel}>
          {buttonText}
        </AddButton>
      )}
    </Container>
  );
};

export default AdditionalFormSectionBanner;
