import quoteClient from 'apiHelpers/quoteClient';
import quoteInProgressClient from 'apiHelpers/quoteInProgressClient';
import isEqual from 'lodash/isEqual';
import React, { Dispatch, useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from 'helpers/arrayHelpers';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { trackAPIError } from 'helpers/eventTracking';
import { QUOTE_UUID_SESSION_KEY, retrieveData } from 'helpers/sessionStorageHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useFormRedirection from 'helpers/useFormRedirection';
import { RootState } from 'state/createStore';
import {
  initialCustomerDetails,
  useCustomerDetails,
} from 'state/formData/customerDetails';
import {
  initialJointPolicyholderDetails,
  useJointPolicyholderDetails,
} from 'state/formData/jointPolicyholderDetails';
import { initialPetsDetails, usePetsDetails } from 'state/formData/petsDetails';
import { initialPolicyDetails, usePolicyDetails } from 'state/formData/policyDetails';
import {
  UPDATE_QUOTE_IN_PROGRESS,
  UpdateQuoteInProgressAction,
} from 'state/formData/quoteInProgress';
import { initialExcessPercentageConfirmation } from 'state/formData/quoteOptions';
import { useInitialiseQuote } from 'state/quote/loadQuoteHelper';

/**
 * Initialises the Quote state in redux on page load. This is used for the details capture pages.
 */
const LoadQuoteInProgressWrapper: React.FC<{ location: Location }> = ({
  location,
  children,
}) => {
  const dispatch = useDispatch<Dispatch<UpdateQuoteInProgressAction>>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialiseQuote = useCallback(useInitialiseQuote(), []);
  const quote = useSelector((state: RootState) => state.quote);
  const [hasInitialisedSessionData, setHasInitialisedSessionData] = useReducer(
    () => true,
    !!quote
  );

  const handleFormRedirection = useFormRedirection();

  const [petDetails] = usePetsDetails();
  const [customerDetails] = useCustomerDetails();
  const [jointPolicyholderDetails] = useJointPolicyholderDetails();
  const [policyDetails] = usePolicyDetails();

  const updateQuoteOptions = useUpdateQuoteOptions();
  const {
    quoteOptions: { excessPercentageConfirmations },
  } = useCurrentQuote();

  // In a single visit to the site (between refreshes/hitting the server) you will only ever need to initialise
  // the session quote in progress once. There are two scenarios:
  // 1) landing on a current session quote page first (quote summary, check details, payment)
  //   - in this case the quote will have been initialised by the QuoteWrapper, and hence the form data populated.
  //   - the form data will be different to the initial values in that case, and hence this initialisation will not run.
  // 2) landing on a current session form page first (about you and your pet)
  //   - in this case we will initialise the data as part of this component then set the initialised flag so it
  //     doesn't reload again, even if the form data has not been changed.
  // If the user ever loads the site with a quoteNumber query param then there is no way to get back to editing a session
  // quote doing a reload (there are no internal nav links for that).
  // To do something more precise, we'd likely need to add something like '/current' into the URLs so that we can know
  // whether the quote we have loaded is in from the session or an explicit load.
  const shouldLoadSessionQuote =
    !hasInitialisedSessionData &&
    isEqual(petDetails, initialPetsDetails) &&
    isEqual(customerDetails, initialCustomerDetails) &&
    isEqual(jointPolicyholderDetails, initialJointPolicyholderDetails) &&
    isEqual(policyDetails, initialPolicyDetails);

  const loadQuoteInProgress = useCallback(async () => {
    const updateQuoteOptionsNumberOfPets = (noOfPets: number | undefined): void => {
      if (noOfPets) {
        if (excessPercentageConfirmations.length > noOfPets) {
          updateQuoteOptions({
            excessPercentageConfirmations: excessPercentageConfirmations.slice(
              0,
              noOfPets
            ),
          });
        } else if (excessPercentageConfirmations.length < noOfPets) {
          const noOfPetsToAdd = noOfPets - excessPercentageConfirmations.length;
          let updatedExcessPercentageConfirmations;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < noOfPetsToAdd; i++) {
            updatedExcessPercentageConfirmations = addItem(
              excessPercentageConfirmations,
              initialExcessPercentageConfirmation
            );
          }

          updateQuoteOptions({
            excessPercentageConfirmations: updatedExcessPercentageConfirmations,
          });
        }
      }
    };

    try {
      const quoteInProgress = await quoteInProgressClient.loadQuoteInProgress();
      dispatch({ type: UPDATE_QUOTE_IN_PROGRESS, quote: quoteInProgress });

      updateQuoteOptionsNumberOfPets(quoteInProgress.petsDetails?.length);

      handleFormRedirection(quoteInProgress, location);
    } catch (error) {
      trackAPIError(error);
      console.error(
        'An error occured when trying to fetch the current quote in progress.',
        error
      );
    }
  }, [
    dispatch,
    excessPercentageConfirmations,
    handleFormRedirection,
    location,
    updateQuoteOptions,
  ]);

  const loadSessionQuoteOrQuoteInProgress = useCallback(async (): Promise<void> => {
    const quoteUuid = retrieveData(QUOTE_UUID_SESSION_KEY);
    if (quoteUuid) {
      try {
        const savedQuote = await quoteClient.getSessionQuote();

        await initialiseQuote(savedQuote);
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          await loadQuoteInProgress();
        } else {
          trackAPIError(error);
          console.error(
            'An error occured when trying to fetch the current quote.',
            error
          );
        }
      } finally {
        setHasInitialisedSessionData();
      }
    } else {
      await loadQuoteInProgress();
      setHasInitialisedSessionData();
    }
  }, [loadQuoteInProgress, initialiseQuote]);

  useEffect(() => {
    if (shouldLoadSessionQuote) {
      loadSessionQuoteOrQuoteInProgress();
    }
  }, [shouldLoadSessionQuote, loadSessionQuoteOrQuoteInProgress, dispatch]);

  return <>{children}</>;
};

export default LoadQuoteInProgressWrapper;
