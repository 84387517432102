import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const GridWithMargin = styled(Grid)`
  & {
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(6)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(6)};
    `}
  }
`;

export const Heading = styled.h2`
  ${fonts.headingMedium}

  margin-bottom: 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
      margin-bottom: 0;
    `}
`;
