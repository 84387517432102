import {
  title_MISS,
  title_MR,
  title_MRS,
  title_MS,
} from 'helpers/referenceDataConstants';
import { ReferenceDataOption } from 'types/referenceData';

const filteredTitleOptions = [title_MISS, title_MR, title_MRS, title_MS];

export const filterTitleOptions = (
  options: ReferenceDataOption[],
  currentTitleValue?: string
): ReferenceDataOption[] =>
  options.filter(
    (option) =>
      filteredTitleOptions.includes(option.value) || option.value === currentTitleValue
  );
