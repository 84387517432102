import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import AdditionalFormSectionBanner from 'forms/AdditionalFormSectionBanner';
import SectionHeading from 'forms/SectionHeading';

export const QuestionFieldWithMarginBottom = styled(QuestionField)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
     margin-bottom: ${spacing(2)};
  `}
`;

export const SectionHeadingWithMargins = styled(SectionHeading)`
  margin-top: ${spacing(5)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
     margin-top: ${spacing(4)};
     margin-bottom: ${spacing(6)};
  `}

  ${mediaQuery.tabletLandscape`
     margin-top: ${spacing(2)};
     margin-bottom: ${spacing(4)};
  `}
`;

export const JointPolicyholderAdditionalFormSectionBanner = styled(
  AdditionalFormSectionBanner
)`
  ${mediaQuery.tabletPortrait`
     padding-bottom: 0;
  `}

  ${mediaQuery.tabletLandscape`
     margin-bottom: ${spacing(4)};
  `}
`;
