import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { GridWithMargin, Heading } from './styles';

export type SectionHeadingProps = {
  heading: string;
} & ComponentProps;

const SectionHeading: React.FC<SectionHeadingProps> = ({ heading, ...props }) => (
  <GridWithMargin alignLeft {...componentProps(props)}>
    <GridItem>
      <Heading>{heading}</Heading>
    </GridItem>
  </GridWithMargin>
);

export default SectionHeading;
