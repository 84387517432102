export const POSTCODE_REGEX = /^( *)(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))( *)$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
export const PHONE_REGEX = /^0[0-9]{10}$/;

// This regex checks that the reference provided starts with 4 letters [a-zA-Z]{4}
// then has 9-11 digits (\d){9,11}
// followed by one final letter at the end [a-zA-Z]{1}
export const POLICY_OR_QUOTE_NUMBER_REGEX = /^[a-zA-Z]{4}(\d){9,11}[a-zA-Z]{1}$/;
// This regex check the format of the value entered in the input field
export const REFERENCE_CODE_REGEX = /^AAAA\d{10}$/;
