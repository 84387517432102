import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import { areEqualIgnoringCase } from 'helpers/stringHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';

export const jointPolicyholderMatchesCustomer = (
  jointPolicyholderDetails: JointPolicyholderDetails,
  customerDetails: CustomerDetails
): boolean =>
  customerDetails.customerFirstName !== '' &&
  areEqualIgnoringCase(
    customerDetails.customerFirstName,
    jointPolicyholderDetails.jointPolicyholderFirstName
  ) &&
  customerDetails.customerLastName !== '' &&
  areEqualIgnoringCase(
    customerDetails.customerLastName,
    jointPolicyholderDetails.jointPolicyholderLastName
  ) &&
  !areDateValuesEqual(customerDetails.customerDob, initialDateValue) &&
  areDateValuesEqual(
    addLeadingZerosToDateValue(customerDetails.customerDob),
    addLeadingZerosToDateValue(jointPolicyholderDetails.jointPolicyholderDob)
  );
