import { evaluateErrors } from '@rsa-digital/evo-shared-components/helpers/forms/helpers';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { navigate } from 'gatsby';
import { useCallback } from 'react';
import useAboutYouRules from 'forms/AboutYouForm/validation';
import useAboutYourPetsRules from 'forms/AboutYourPetsForm/AboutYourPet/validation';
import { QuoteInProgress } from 'state/formData/quoteInProgress';
import { detailsCaptureRoutes, quoteAndBuyRoutes } from './routingHelper';

type Form = keyof typeof detailsCaptureRoutes;

const formFlow: Form[] = ['aboutYourPet', 'aboutYou', 'aboutYourPolicy'];
const formFlowPageRoutes: string[] = formFlow.map((page) => quoteAndBuyRoutes[page]);

const standardisePathName = (path: string): string => path.toLowerCase();

const useFormRedirection = (): ((
  quoteInProgress: QuoteInProgress,
  location: Location
) => void) => {
  const aboutYourPetRules = useAboutYourPetsRules();
  const aboutYouRules = useAboutYouRules();

  const isValid = <TData>(data: TData, rules: ValidationRules<TData>): boolean =>
    Object.keys(evaluateErrors(data, rules, true)).length === 0;

  return useCallback(
    (quoteInProgress: QuoteInProgress, location: Location): void => {
      const aboutYourPetData = quoteInProgress.petsDetails;
      const aboutYouData = quoteInProgress.customerDetails;

      const isFormValid: { [T in Form]: boolean } = {
        aboutYourPet:
          !!aboutYourPetData &&
          isValid({ petsDetails: aboutYourPetData }, aboutYourPetRules.errors),
        aboutYou: !!aboutYouData && isValid(aboutYouData, aboutYouRules.errors),
        // We never need to redirect TO this form as it's last, so we needn't bother
        // doing the validity checks.
        aboutYourPolicy: false,
      };

      const firstIncompleteForm = formFlow.find((p) => !isFormValid[p]);
      const firstIncompleteFormPageRoute =
        firstIncompleteForm && quoteAndBuyRoutes[firstIncompleteForm];

      if (
        firstIncompleteFormPageRoute &&
        formFlowPageRoutes.indexOf(standardisePathName(location.pathname) as Form) >
          formFlowPageRoutes.indexOf(firstIncompleteFormPageRoute)
      ) {
        navigate(firstIncompleteFormPageRoute);
      }
    },
    [aboutYouRules, aboutYourPetRules]
  );
};

export default useFormRedirection;
