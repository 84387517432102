import RemoveButton from '@rsa-digital/evo-shared-components/components/RemoveButton';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { WarningPanel } from 'components/StatusPanel';

export const WarningPanelWithMarginBottom = styled(WarningPanel)`
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const RemoveButtonWithMarginBottom = styled(RemoveButton)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: 0;
  `}
`;
