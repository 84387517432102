import { ButtonProps } from '@rsa-digital/evo-shared-components/components/Button';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle } from 'helpers/eventTracking';
import {
  AddButtonWithTopMargin,
  ContentWrapper,
  Heading,
  IconWrapper,
  StyledPanel,
} from './styles';

type PromotionalBoxProps = {
  icon?: string;
  heading?: string;
  information: string;
  button?: ButtonProps & { buttonText: string };
  pageTitle: PageTitle;
} & ComponentProps;

const PromotionalBox: React.FC<PromotionalBoxProps> = ({
  icon,
  heading,
  information,
  button,
  pageTitle,
  ...rest
}) => {
  return (
    <StyledPanel {...componentProps(rest)}>
      <ContentWrapper>
        {icon && (
          <IconWrapper>
            <Icon size="large" name={icon} color={colors.core01} />
          </IconWrapper>
        )}
        <div>
          {heading && <Heading>{heading}</Heading>}
          <RichTextWithModal html={information} pageTitle={pageTitle} />
          {button && (
            <AddButtonWithTopMargin {...button}>
              {button.buttonText}
            </AddButtonWithTopMargin>
          )}
        </div>
      </ContentWrapper>
    </StyledPanel>
  );
};

export default PromotionalBox;
