import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  ${fonts.headingSmall};
`;

export const StyledText = styled(RichTextWithModal)`
  margin-top: 0;
  margin-bottom: ${spacing(4)};

  div {
    ${fonts.paragraph};

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  p {
    ${fonts.paragraph};

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }
`;

export const Container = styled(PageWidthContainer)`
  background-color: ${colors.core02};
  padding-top: ${spacing(4)};
  padding-bottom: ${spacing(4)};
`;
