import {
  addYearsToDate,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationErrorAndWarningRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { assumptionsIncludeId } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { CustomerDetails } from 'state/formData/customerDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';
import emailValidationRules from '../../helpers/emailValidationRules';
import phoneValidationRules from '../../helpers/phoneValidationRule';

type CsAboutYouErrorMessages = {
  csPetAboutYouQuestionsV2: {
    customer_title: CsErrorsMissingOnly;
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    main_telephone: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
    email: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
    number_of_pets_in_household: {
      error_messages: {
        missing: string;
        less_than_pets_on_policy: string;
      };
    };
  };
  csPetAggregatorsV2: {
    assumptions: {
      one_pet_in_household: {
        warning: string;
      };
      two_pets_in_household: {
        warning: string;
      };
      three_pets_in_household: {
        warning: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouQuestionsV2 {
      customer_title {
        error_messages {
          missing
        }
      }
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      main_telephone {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
      email {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
      number_of_pets_in_household {
        error_messages {
          missing
          less_than_pets_on_policy
        }
      }
    }
    csPetAggregatorsV2 {
      assumptions {
        one_pet_in_household {
          warning
        }
        two_pets_in_household {
          warning
        }
        three_pets_in_household {
          warning
        }
      }
    }
  }
`;

const useAboutYouRules = (): ValidationErrorAndWarningRules<CustomerDetails> => {
  const errorMessages = useStaticQuery<CsAboutYouErrorMessages>(query);
  const [petsDetails] = usePetsDetails();

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  return {
    errors: {
      customerTitle: [
        required(
          errorMessages.csPetAboutYouQuestionsV2.customer_title.error_messages.missing
        ),
      ],
      customerFirstName: [
        required(
          errorMessages.csPetAboutYouQuestionsV2.first_name.error_messages.missing
        ),
      ],
      customerLastName: [
        required(errorMessages.csPetAboutYouQuestionsV2.last_name.error_messages.missing),
      ],
      customerTelephone: phoneValidationRules(
        errorMessages.csPetAboutYouQuestionsV2.main_telephone.error_messages
      ),
      customerEmail: emailValidationRules(
        errorMessages.csPetAboutYouQuestionsV2.email.error_messages
      ),
      customerDob: [
        requiredDateValue(
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages
            .invalid_month
        ),
        dateValueValid(
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.invalid_date
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages
            .year_too_short
        ),
        dateValueLessThanOrEqualTo(
          new Date(),
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages
            .date_in_future
        ),
        dateValueLessThanOrEqualTo(
          addYearsToDate(new Date(), -18),
          errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.too_young
        ),
        {
          validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
          errorMessage:
            errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages
              .invalid_date,
        },
      ],
      numberOfPetsInHousehold: [
        required(
          errorMessages.csPetAboutYouQuestionsV2.number_of_pets_in_household
            .error_messages.missing
        ),
        {
          validityCondition: (value) => !!value && petsDetails.length <= value,
          errorMessage:
            errorMessages.csPetAboutYouQuestionsV2.number_of_pets_in_household
              .error_messages.less_than_pets_on_policy,
        },
      ],
    },
    warnings: {
      numberOfPetsInHousehold: [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'one_pet_in_household'),
          {
            validityCondition: (value) => value !== 1,
            errorMessage:
              errorMessages.csPetAggregatorsV2.assumptions.one_pet_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'two_pets_in_household'),
          {
            validityCondition: (value) => value !== 2,
            errorMessage:
              errorMessages.csPetAggregatorsV2.assumptions.two_pets_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'three_pets_in_household'),
          {
            validityCondition: (value) => value !== 3,
            errorMessage:
              errorMessages.csPetAggregatorsV2.assumptions.three_pets_in_household
                .warning,
          }
        ),
      ],
    },
  };
};

export default useAboutYouRules;
