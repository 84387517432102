import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import {
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { AssumptionId } from 'businessLogic/aggregatorAssumptions';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { ReactElement, useEffect, useState } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import FormFooter from 'components/FormFooter';
import { NameInput } from 'components/NameInput/styles';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import useJointPolicyholderRules from 'forms/JointPolicyholderForm/validation';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackFieldError,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_NAME, INPUT_REGEX_NAME_SPECIAL_CHARS } from 'helpers/inputRegexes';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import useUpdateNumberOfPets from 'helpers/useUpdateNumberOfPets';
import {
  useAssumptionsAgreement,
  useTouchAssumption,
} from 'state/formData/assumptionsAgreement';
import { CustomerDetails, useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsAdditionalFormSectionBanner } from 'types/contentStack';
import { Question } from 'types/forms';
import { ReferenceDataOption } from 'types/referenceData';
import AddressForm from './AddressForm';
import useAddressRules from './AddressForm/validation';
import { filterTitleOptions } from './filterTitleOptions';
import useAboutYouQuestions, { AboutYouQuestions } from './questions';
import {
  JointPolicyholderAdditionalFormSectionBanner,
  QuestionFieldWithMarginBottom,
  SectionHeadingWithMargins,
} from './styles';
import useAboutYouRules from './validation';

type AboutYouFormProps = {
  moveNext: () => void;
  moveBack: () => void;
};

type AboutYouFormData = {
  csPetAboutYou: {
    next_button_text: string;
    joint_policyholder_banner: CsAdditionalFormSectionBanner;
    joint_policyholder_section_heading: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYou {
      next_button_text
      joint_policyholder_banner {
        heading_text
        body_text
        button_text
        button_aria_label
      }
      joint_policyholder_section_heading
    }
  }
`;

const AboutYouForm: React.FC<AboutYouFormProps> = ({ moveNext, moveBack }) => {
  const {
    csPetAboutYou: {
      next_button_text,
      joint_policyholder_banner,
      joint_policyholder_section_heading,
    },
  } = useStaticQuery<AboutYouFormData>(query);

  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();
  const [petsDetails] = usePetsDetails();

  const jointPolicyholderSectionId = 'joint-policyholder-section';
  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();

  const [displayJointPolicyholderForm, setDisplayJointPolicyholderForm] = useState(
    jointPolicyholderDetails.includeJointPolicyholder
  );

  const numberOfPetsInQuote = petsDetails.length;
  const customerRules = useAboutYouRules();
  const addressRules = useAddressRules();
  const jointPolicyHolderRules = useJointPolicyholderRules();

  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    customerDetails.customerTitle
  );

  const formErrorRules = {
    ...customerRules.errors,
    ...addressRules,
    ...(jointPolicyholderDetails.includeJointPolicyholder ? jointPolicyHolderRules : {}),
  };

  const formDetails = {
    ...customerDetails,
    ...jointPolicyholderDetails,
  };

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings(
    formDetails,
    formErrorRules,
    customerRules.warnings,
    trackFieldError
  );

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const assumptionIdToFormFieldValidationMapping: Partial<Record<
    AssumptionId,
    () => void
  >> = {
    one_pet_in_household: () => showValidation('numberOfPetsInHousehold'),
    two_pets_in_household: () => showValidation('numberOfPetsInHousehold'),
  };

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const questionWithNames: Question = {
    ...questions.numberOfPetsInHousehold.question,
    questionText: petNameReplacer(
      combinedPetNames,
      questions.numberOfPetsInHousehold.question.questionText
    ),
  };

  useEffect(() => {
    if (assumptions !== undefined && !assumptionsAgreement.assumptionsAgreed) {
      assumptions.assumptions.forEach(({ id }) =>
        assumptionIdToFormFieldValidationMapping[id]?.()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<AboutYouQuestions> & StringField<CustomerDetails>,
    options: ReferenceDataOption[] | undefined,
    isPii?: boolean,
    analyticsDescription?: string
  ): ReactElement => (
    <QuestionField
      question={questions[id]}
      errorText={getError(id)}
      warningText={getWarning(id)}>
      <SelectInput
        id={id}
        value={customerDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackFormDropdownSelect(
            analyticsDescription || id,
            isPii ? undefined : e.target.value
          );
          updateCustomerDetails({ [id]: e.target.value });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackFormDropdownFocus(analyticsDescription || id, PageTitle.AboutYou)}
        loading={!options}
      />
    </QuestionField>
  );

  const { addPet } = useUpdateNumberOfPets();

  const touchAssumption = useTouchAssumption();

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {renderRefDataQuestion(
        'customerTitle',
        filteredTitlesRefData,
        false,
        'Customer title'
      )}
      <QuestionField
        question={questions.customerFirstName}
        errorText={getError('customerFirstName')}>
        <NameInput
          id="customerFirstName"
          placeholder={questions.customerFirstName.placeholder}
          maxLength={40}
          value={customerDetails.customerFirstName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerLastName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerFirstName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerFirstName');
          }}
          onFocus={trackFormTextFieldFocus('Customer first name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerLastName}
        errorText={getError('customerLastName')}>
        <NameInput
          id="customerLastName"
          placeholder={questions.customerLastName.placeholder}
          maxLength={30}
          value={customerDetails.customerLastName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerLastName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerLastName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerLastName');
          }}
          onFocus={trackFormTextFieldFocus('Customer last name')}
        />
      </QuestionField>
      <QuestionField question={questions.customerDob} errorText={getError('customerDob')}>
        <DateInput
          id="customerDob"
          value={customerDetails.customerDob}
          onChange={(e) =>
            updateCustomerDetails({
              customerDob: e,
            })
          }
          onBlur={() => {
            updateCustomerDetails({
              customerDob: addLeadingZerosToDateValue(customerDetails.customerDob),
            });
            showValidation('customerDob');
          }}
          onFocus={trackFormTextFieldFocus('Customer DOB')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerEmail}
        errorText={getError('customerEmail')}>
        <TextInput
          id="customerEmail"
          inputMode="email"
          placeholder={questions.customerEmail.placeholder}
          maxLength={254}
          value={customerDetails.customerEmail}
          onChange={(e) => {
            updateCustomerDetails({
              customerEmail: e.target.value.replaceAll(' ', ''),
            });
          }}
          onBlur={() => showValidation('customerEmail')}
          onFocus={trackFormTextFieldFocus('Customer email')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerTelephone}
        errorText={getError('customerTelephone')}>
        <NameInput
          id="customerTelephone"
          inputMode="tel"
          placeholder={questions.customerTelephone.placeholder}
          maxLength={11}
          value={customerDetails.customerTelephone}
          onChange={(e) => {
            updateCustomerDetails({
              customerTelephone: e.target.value,
            });
          }}
          numbersOnly
          onBlur={() => showValidation('customerTelephone')}
          onFocus={trackFormTextFieldFocus('Customer telephone')}
        />
      </QuestionField>
      <AddressForm
        addressDetails={customerDetails}
        updateAddressDetails={updateCustomerDetails}
        formValidation={{ showValidation, getError }}
      />
      <QuestionFieldWithMarginBottom
        question={questionWithNames}
        errorText={getError('numberOfPetsInHousehold')}
        warningText={getWarning('numberOfPetsInHousehold')}>
        <SegmentedSelector
          id="numberOfPetsInHousehold"
          value={customerDetails.numberOfPetsInHousehold?.toString()}
          shownOptions={5}
          options={[
            {
              name: '1',
              value: '1',
            },
            {
              name: '2',
              value: '2',
            },
            {
              name: '3',
              value: '3',
            },
            {
              name: '4',
              value: '4',
            },
            {
              name: '5+',
              value: '5',
            },
          ]}
          onChange={(event) => {
            touchAssumption([
              'one_pet_in_household',
              'two_pets_in_household',
              'three_pets_in_household',
            ]);
            updateCustomerDetails({
              numberOfPetsInHousehold: Number(event.target.value),
            });
            trackRadioButtonClick('Number of pets in household', event.target.value);
            showValidation('numberOfPetsInHousehold');
          }}
        />
      </QuestionFieldWithMarginBottom>
      {customerDetails.numberOfPetsInHousehold &&
        numberOfPetsInQuote < 3 &&
        customerDetails.numberOfPetsInHousehold > numberOfPetsInQuote && (
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
              <PromotionalBox
                pageTitle={PageTitle.AboutYou}
                id="number-of-pets-promotional-box"
                icon={
                  unwrapSingleton(questions.numberOfPetsInHousehold.promotionalBox.icon)
                    ?.icon_code
                }
                heading={questions.numberOfPetsInHousehold.promotionalBox.heading}
                information={questions.numberOfPetsInHousehold.promotionalBox.information}
                button={{
                  buttonText:
                    questions.numberOfPetsInHousehold.promotionalBox.button_text,
                  screenReaderText:
                    questions.numberOfPetsInHousehold.promotionalBox
                      .button_screenreader_text,
                  onClick: () => {
                    addPet();
                    /* This use of setTimeout forces scrollAndFocusInput to be called after
                     * updatePetsDetails, so that we attempt to scroll to the newly added pet
                     * form section *after* the section has been rendered in the DOM.
                     *
                     * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
                     * details about this implementation.
                     */
                    navigate(quoteAndBuyRoutes.aboutYourPet);
                    setTimeout(() =>
                      scrollAndFocusInput(`petDetails[${petsDetails.length}]`)
                    );
                    trackTextButtonClick(
                      PageTitle.AboutYou,
                      'Add a pet - number of pets in household'
                    );
                  },
                }}
              />
            </GridItem>
          </Grid>
        )}
      {displayJointPolicyholderForm ? (
        <section aria-labelledby={jointPolicyholderSectionId}>
          <SectionHeadingWithMargins
            heading={joint_policyholder_section_heading}
            id={jointPolicyholderSectionId}
          />
          <JointPolicyholderForm
            removeJointPolicyholderButtonOnClick={() =>
              setDisplayJointPolicyholderForm(false)
            }
            getError={getError}
            showValidation={showValidation}
          />
        </section>
      ) : (
        <JointPolicyholderAdditionalFormSectionBanner
          data-cy="addJointPolicyholderBanner"
          headingText={joint_policyholder_banner.heading_text}
          bodyText={joint_policyholder_banner.body_text}
          buttonText={joint_policyholder_banner.button_text}
          buttonAriaLabel={joint_policyholder_banner.button_aria_label}
          pageTitle={PageTitle.AboutYou}
          buttonId="addJointPolicyholderButton"
          addFormSectionButtonClick={() => {
            updateJointPolicyholderDetails({ includeJointPolicyholder: true });
            setDisplayJointPolicyholderForm(true);
            trackTextButtonClick(PageTitle.AboutYou, 'Add joint policy holder');
          }}
        />
      )}
      <FormFooter
        moveNextButton={{
          text: next_button_text,
          onClick: () => trackTextButtonClick(PageTitle.AboutYou, 'Submit details'),
          id: 'aboutyou_continue',
        }}
        backButton={{
          onClick: () => {
            moveBack();
            trackTextButtonClick(PageTitle.AboutYou, 'Move back');
          },
        }}
        pageTitle={PageTitle.AboutYou}
      />
    </form>
  );
};

export default AboutYouForm;
