import { ButtonStyle } from '@rsa-digital/evo-shared-components/components/Button/styles';
import AddressInput from '@rsa-digital/evo-shared-components/components/Form/AddressInput';
import {
  FindAddressButton,
  FindAddressGridItem,
  PostcodeLookupGridItem,
} from '@rsa-digital/evo-shared-components/components/Form/AddressInput/PostcodeSearch/styles';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import styled from 'styled-components';

export const AddressInputStyled = styled(AddressInput)`
  ${FindAddressButton} {
    margin-top: 0;
    
    ${ButtonStyle}, &${ButtonStyle}  {
      &[data-variant="secondary"] {
        border-color: ${colors.neutral01};
        padding: ${spacing(1)} ${spacing(2)};
        font-size: 1.125rem;
        line-height: 1.75rem;

        ${mediaQuery.tabletLandscape`
          padding: ${spacing(2.5)} ${spacing(3)};
          font-size: 1.25rem;
          line-height: 2rem;
        `}
      }
    }
  }

  ${PostcodeLookupGridItem} {
    ${gridItemPropsOverride(
      { desktop: 3, tabletLandscape: 3, tabletPortrait: 4, mobile: 2 },
      { desktop: 6, tabletLandscape: 6, tabletPortrait: 6, mobile: 4 }
    )}
  }

  ${FindAddressGridItem} {
    ${gridItemPropsOverride(
      { desktop: 3, tabletLandscape: 3, tabletPortrait: 2, mobile: 2 },
      { desktop: 6, tabletLandscape: 6, tabletPortrait: 6, mobile: 4 }
    )}
  }
`;
