import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { jointPolicyholderMatchesCustomer } from 'businessLogic/jointPolicyholder';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import { filterTitleOptions } from 'forms/AboutYouForm/filterTitleOptions';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useCustomerDetails } from 'state/formData/customerDetails';
import {
  initialJointPolicyholderDetails,
  JointPolicyholderDetails,
  useJointPolicyholderDetails,
} from 'state/formData/jointPolicyholderDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import useJointPolicyholderQuestions from './questions';
import { RemoveButtonWithMarginBottom, WarningPanelWithMarginBottom } from './styles';

type JointPolicyholderFormProps = {
  removeJointPolicyholderButtonOnClick: MouseEventHandler;
  getError: FieldFunction<JointPolicyholderDetails, string | undefined>;
  showValidation: FieldFunction<JointPolicyholderDetails, void>;
};

type JointPolicyholderFormData = {
  csPetAboutYou: {
    joint_policyholder_warning_panel: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYou {
      joint_policyholder_warning_panel
    }
  }
`;

const JointPolicyholderForm: React.FC<JointPolicyholderFormProps> = ({
  removeJointPolicyholderButtonOnClick,
  getError,
  showValidation,
}) => {
  const {
    csPetAboutYou: { joint_policyholder_warning_panel },
  } = useStaticQuery<JointPolicyholderFormData>(query);

  const questions = useJointPolicyholderQuestions();
  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();
  const [customerDetails] = useCustomerDetails();

  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    jointPolicyholderDetails.jointPolicyholderTitle
  );

  const removeButtonText = 'Remove joint policyholder';

  return (
    <>
      <QuestionField
        question={questions.jointPolicyholderTitle}
        errorText={getError('jointPolicyholderTitle')}>
        <SelectInput
          id="jointPolicyholderTitle"
          value={jointPolicyholderDetails.jointPolicyholderTitle}
          options={filteredTitlesRefData}
          placeholder={questions.jointPolicyholderTitle.placeholder}
          onChange={(e) => {
            trackFormDropdownSelect('Joint policyholder title', e.target.value);
            updateJointPolicyholderDetails({
              jointPolicyholderTitle: e.target.value,
            });
          }}
          onBlur={() => showValidation('jointPolicyholderTitle')}
          onFocus={trackFormDropdownFocus('Joint policyholder title', PageTitle.AboutYou)}
          loading={!filteredTitlesRefData}
        />
      </QuestionField>
      <QuestionField
        question={questions.jointPolicyholderFirstName}
        errorText={getError('jointPolicyholderFirstName')}>
        <NameInput
          id="jointPolicyholderFirstName"
          placeholder={questions.jointPolicyholderFirstName.placeholder}
          maxLength={40}
          value={jointPolicyholderDetails.jointPolicyholderFirstName}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_NAME)) {
              updateJointPolicyholderDetails({
                jointPolicyholderFirstName: capitaliseCharacterAfterHyphenAndSpace(
                  e.target.value
                ),
              });
            }
          }}
          onBlur={() => showValidation('jointPolicyholderFirstName')}
          onFocus={trackFormTextFieldFocus('Joint policyholder first name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.jointPolicyholderLastName}
        errorText={getError('jointPolicyholderLastName')}>
        <NameInput
          id="jointPolicyholderLastName"
          placeholder={questions.jointPolicyholderLastName.placeholder}
          maxLength={30}
          value={jointPolicyholderDetails.jointPolicyholderLastName}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_NAME)) {
              updateJointPolicyholderDetails({
                jointPolicyholderLastName: capitaliseCharacterAfterHyphenAndSpace(
                  e.target.value
                ),
              });
            }
          }}
          onBlur={() => showValidation('jointPolicyholderLastName')}
          onFocus={trackFormTextFieldFocus('Joint policyholder last name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.jointPolicyholderDob}
        errorText={getError('jointPolicyholderDob')}>
        <DateInput
          id="jointPolicyholderDob"
          value={jointPolicyholderDetails.jointPolicyholderDob}
          onChange={(e) =>
            updateJointPolicyholderDetails({
              jointPolicyholderDob: e,
            })
          }
          onBlur={() => {
            updateJointPolicyholderDetails({
              jointPolicyholderDob: addLeadingZerosToDateValue(
                jointPolicyholderDetails.jointPolicyholderDob
              ),
            });
            showValidation('jointPolicyholderDob');
          }}
          onFocus={trackFormTextFieldFocus('Joint policyholder dob')}
        />
      </QuestionField>
      {jointPolicyholderMatchesCustomer(jointPolicyholderDetails, customerDetails) && (
        <FieldGrid alignLeft>
          <WarningPanelWithMarginBottom data-cy="jointPolicyholder-warningPanel">
            {joint_policyholder_warning_panel}
          </WarningPanelWithMarginBottom>
        </FieldGrid>
      )}
      <RemoveButtonWithMarginBottom
        onClick={(e) => {
          updateJointPolicyholderDetails(initialJointPolicyholderDetails);
          removeJointPolicyholderButtonOnClick(e);
          trackTextButtonClick(PageTitle.AboutYou, 'Remove joint policy holder');
        }}
        id="removeJointPolicyholderButton">
        {removeButtonText}
      </RemoveButtonWithMarginBottom>
    </>
  );
};

export default JointPolicyholderForm;
